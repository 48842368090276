import { Image } from '../interfaces/ComicInterface';
import '../styles/uiStyles.css'

interface comicProps {
    id: number | undefined;
    title: string | undefined;
    image: Image | undefined;
}

export default function ComicCardSmall (props: comicProps) {
    return(
        <div className='comic-card-small' key={props.id}>
            <img 
                style={{ paddingTop: 5 }} 
                className='image-card' 
                src={
                    (props.image && props.image.path && props.image.extension) ?
                    props.image.path + '.' + props.image.extension :
                    ''
                } 
                alt='Imagen no disponible'/>
            <div className='info-card-small'>
                <h4>{props.title}</h4>
            </div>
        </div>
    )
}