import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks";
import LoadingSpinner from "../Components/LoadingSpinner";
import { useNavigate, useParams } from 'react-router-dom'
import '../styles/screenStyles.css';
import Header from "../Components/Header";
import { getComicAsync } from "../redux/comicsSlice";

export default function Comic() {
    const dispatch = useAppDispatch();
    const params = useParams();
    const comic = useAppSelector(state => state.comics.comic);
    const state = useAppSelector(state => state.comics.statusComic);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getComicAsync(Number(params.comicID)));
    },[params, dispatch]);

    return(
        <div className="main">
            <Header title={"Comic"} />
            <div className="flex-center">
                {state === 'loading' &&
                    <LoadingSpinner />
                }
                {state === 'idle' &&
                    <div className="flex-center">
                        <img 
                            className='comic-image' 
                            src={
                                (comic?.thumbnail && comic.thumbnail.path && comic.thumbnail.extension) ?
                                comic.thumbnail.path + '.' + comic.thumbnail.extension :
                                ''
                            } 
                            alt='Imagen no disponible'
                        />
                        <h1>{comic?.title}</h1>
                        {comic?.description &&
                            <p><b>Descripción:</b> {comic.description}</p>
                        }
                        {comic?.characters?.items && comic.characters?.items.length > 0 &&
                            <div style={{ width: '80%' }}>
                                <p><b>Personajes:</b></p>
                                <ul>
                                    {comic.characters?.items.map(char => 
                                        <li 
                                            onClick={() => navigate(`character/${char.resourceURI?.split('characters/')[1]}`)}
                                            key={char.resourceURI}
                                        >
                                            {char.name}
                                        </li>
                                    )}
                                </ul>
                            </div>
                        } 
                    </div>
                }
                {state === 'failed' &&
                    <div className="alert alert-danger" role="alert">
                        Hubo un error al pedir los datos del comic
                    </div>
                }
            </div>
        </div>
    )
    
}