import React from 'react';
import './App.css';
import {
  Routes,
  Route
} from "react-router-dom";
import Home from './Screens/Home';
import Comic from './Screens/Comic';
import Character from './Screens/Character';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="comic/*" element={
          <Routes>
            <Route path=':comicID' element={<Comic />} />
            <Route path=":comicID/character/:characterID" element={<Character />}/>
          </Routes>
        } >
        </Route>
      </Routes>
  );
}

export default App;
