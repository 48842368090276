import { CharacterDataWrapper } from "../interfaces/CharacterInterface";
import { ComicDataWrapper } from "../interfaces/ComicInterface";

export interface CharacterComicsProps{
    id: number;
    limit: number | undefined;
}

export default class CharactersApi {
    public baseUrl: String;
    public token: String;
    constructor(baseUrl: String, apiToken: String) {
        this.baseUrl = baseUrl;
        this.token = apiToken;
    }
    getCharacter(id: number): Promise<CharacterDataWrapper>{
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + '/characters/' + id + '?apikey=' + this.token, { method: 'GET' })
            .then((json) => {
                json.json()
                .then(response => resolve(response));
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
    getCharacterComics(props: CharacterComicsProps): Promise<ComicDataWrapper> {
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + '/characters/' + props.id + '/comics?apikey=' + this.token + (props.limit ? '&limit=' + props.limit : ''), { method: 'GET' })
            .then((json) => {
                json.json()
                .then(response => resolve(response));
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
}