import { ComicDataWrapper } from "../interfaces/ComicInterface";

export default class ComicsApi {
    public baseUrl: String;
    public token: String;
    public pageSize = Number(process.env.REACT_APP_PAGE_SIZE);
    constructor(baseUrl: String, apiToken: String) {
        this.baseUrl = baseUrl;
        this.token = apiToken;
    }
    getPage(pageNumber: number, orderBy: String | undefined): Promise<ComicDataWrapper> {
        let query = "&limit=" + this.pageSize + "&offset=" + pageNumber*this.pageSize;
        if(orderBy){
            query = query + "&orderBy=" + orderBy;
        }
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + '/comics?apikey=' + this.token + query, { method: 'GET' })
            .then((json) => {
                json.json()
                .then(response => resolve(response));
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
    getComic(id: number): Promise<ComicDataWrapper> {
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + '/comics/' + id + '?apikey=' + this.token, { method: 'GET' })
            .then((json) => {
                json.json()
                .then(response => resolve(response));
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
    getComicCharacters(id: String): Promise<ComicDataWrapper>{
        return new Promise((resolve, reject) => {
            fetch(this.baseUrl + '/comics/' + id + '/characters?apikey=' + this.token, { method: 'GET' })
            .then((json) => {
                json.json()
                .then(response => resolve(response));
            })
            .catch((error) => {
                reject(error);
            });
        });
    }
}