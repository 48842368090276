import * as React from 'react';
import { Character } from '../interfaces/CharacterInterface';
import { Image } from '../interfaces/ComicInterface';
import { NavigateFunction } from 'react-router-dom'
import '../styles/uiStyles.css'

interface comicProps {
    id: number | undefined;
    title: string | undefined;
    description: string | undefined;
    image: Image | undefined;
    characters: Character[] | undefined;
    navigate: NavigateFunction;
}

export default function ComicCard (props: comicProps) {
    return(
        <div className='comic-card' key={props.id}>
            <div className='data-card'>
                <img 
                    className='image-card' 
                    src={
                        (props.image && props.image.path && props.image.extension) ?
                        props.image.path + '.' + props.image.extension :
                        ''
                    } 
                    alt='Imagen no disponible'/>
                <div className='info-card'>
                    <h4>{props.title}</h4>
                    {props.description &&
                        <p className='text'><b>Descripción:</b> {props.description}</p>
                    }
                    {props.characters && props.characters.length > 0 && 
                    <div>
                        <p className='text'><b>Personajes:</b></p>
                        <ul>
                            {props.characters.map(char => <li key={char.resourceURI}>{char.name}</li>)}
                        </ul>
                    </div>
                    }
                </div>
            </div>
            <div className='learn-more-button' onClick={() => props.navigate(`comic/${props.id}`)}>
                Más información
            </div>
        </div>
    )
}