import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import ComicCard from "../Components/ComicCard";
import LoadingSpinner from "../Components/LoadingSpinner";
import { getComicsAsync, setPage } from "../redux/comicsSlice";
import { useNavigate } from 'react-router-dom'
import '../styles/screenStyles.css';
import Header from "../Components/Header";
import OrderBy from "../Components/OrderBy";


export default function Home() {
    const dispatch = useAppDispatch();
    const comics = useAppSelector(state => state.comics.comics);
    const state = useAppSelector(state => state.comics.statusComics);
    const offset = useAppSelector(state => state.comics.offset);
    const total = useAppSelector(state => state.comics.total); 
    const count = useAppSelector(state => state.comics.count);
    const page = useAppSelector(state => state.comics.page);
    const order = useAppSelector(state => state.comics.order);
    
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getComicsAsync({page: page, orderBy: order}));
    },[page, order, dispatch]);

    return(
    <div className="main">
        <Header title="Lista de comics"/>
        <div className="main flex-center">
            <div style={{ width: '100%', padding: 20 }}>
                <OrderBy />
            </div>
            <div className="display-cards">
                {state === 'loading' &&
                    <div style={{ height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                        <LoadingSpinner />
                    </div>
                }
                {state === 'idle' && comics?.map(comic => 
                    <ComicCard 
                        id={comic?.id} 
                        title={comic?.title} 
                        description={comic?.description} 
                        image={comic?.thumbnail}
                        characters={comic?.characters?.items}
                        navigate={navigate}
                    />
                )}
                {state === 'failed' &&
                <div className="alert alert-danger" role="alert">
                    Hubo un error al pedir los datos de los comics
                </div>
                }
            </div>
            {state === 'idle' && (offset !== undefined && count !== undefined && total !== undefined) &&
                <div className="container-buttons-pages">
                    { (page > 0) &&
                        <button 
                            className="page-button"
                            onClick={() => dispatch(setPage(page-1))}
                        >
                            Anterior
                        </button>
                    }
                    <h4 style={{ color: 'white', marginRight: 5, marginLeft: 5 }}>{offset} - {offset + count} de {total}</h4>
                    {((offset + count) < total) &&
                        <button 
                            className="page-button"
                            onClick={() => dispatch(setPage(page+1))}
                        >
                            Siguiente
                        </button>
                    }
                </div>
            }
        </div>
    </div>
    )
}