import CharactersApi from "./charactersApi";
import ComicsApi from "./comicsApi";

let baseUrl = process.env.REACT_APP_BASE_URL;
let apiToken = process.env.REACT_APP_MARVEL_APIKEY;
if(!baseUrl){
    baseUrl = "";
}
if(!apiToken){
    apiToken = "";
}

const Api = {
    baseUrl: baseUrl,
    generateImageUri (name: String, path: String) {
        if (name && baseUrl) {
            return baseUrl + (path ? path : '') + name;
        }
    },
    comics: new ComicsApi(baseUrl, apiToken),
    characters: new CharactersApi(baseUrl, apiToken)
};

export default Api;