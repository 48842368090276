import { useNavigate } from 'react-router-dom';
import logo from '../assets/marvel.svg';
import '../styles/uiStyles.css'

interface HeaderProps{
    title?: string;
}

export default function Header(props: HeaderProps) {
    const navigate = useNavigate();
    return (
      <header>
        <div className='toolbar'>
            <h3 onClick={() => navigate(-1)} style={{marginRight: 20, color: 'white'}}>Volver</h3>
            <div style={{  height: '100%', width: 1, backgroundColor: 'white', marginRight: 20 }} />
            <h1 className='h1-header'>{props.title}</h1>

            <div style={{ flexGrow: 1 }} />

            <div className='left-container-header'>
                <span className='span-header'>
                  <img className='logo' src={logo} alt="Marvel Logo" />
                </span>
            </div>
        </div>
      </header>
    );
};