import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import marvelApi from '../api';
import { CharacterComicsProps } from '../api/charactersApi';
import { Character } from '../interfaces/CharacterInterface';
import { Comic } from '../interfaces/ComicInterface';

export interface CharacterComicsState {
  character: Character | undefined;
  characterComics: Comic[] | undefined;
  total: number | undefined;
  statusCharacter: 'idle' | 'loading' | 'failed';
  statusCharacterComics: 'idle' | 'loading' | 'failed';
}

const initialState: CharacterComicsState = {
  character: undefined,
  characterComics: [],
  total: undefined,
  statusCharacter: 'idle',
  statusCharacterComics: 'idle',
};

export const getCharacterComicsAsync = createAsyncThunk(
  'character/getComics',
  async (data: CharacterComicsProps) => {
    const response = await marvelApi.characters.getCharacterComics(data);
    return response.data;
  }
);

export const getCharacterAsync = createAsyncThunk(
  'character/getCharacter',
  async (id: number) => {
    const response = await marvelApi.characters.getCharacter(id);
    return response.data;
  }
);

export const charactersSlice = createSlice({
  name: 'comics',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getCharacterComicsAsync.pending, (state) => {
        state.statusCharacterComics = 'loading';
        state.characterComics = undefined;
      })
      .addCase(getCharacterComicsAsync.fulfilled, (state, action) => {
        state.statusCharacterComics = 'idle';
        state.characterComics = action.payload?.results;
        state.total = action.payload?.total;
      })
      .addCase(getCharacterComicsAsync.rejected, (state) => {
        state.statusCharacterComics = 'failed';
      });
    builder
      .addCase(getCharacterAsync.pending, (state) => {
        state.statusCharacter = 'loading';
        state.character = undefined;
      })
      .addCase(getCharacterAsync.fulfilled, (state, action) => {
        state.statusCharacter = 'idle';
        state.character = action.payload?.results ? action.payload?.results[0] : undefined;
      })
      .addCase(getCharacterAsync.rejected, (state) => {
        state.statusCharacter = 'failed';
      });
  },
});

export default charactersSlice.reducer;
