import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../app/hooks";
import LoadingSpinner from "../Components/LoadingSpinner";
import { useParams } from 'react-router-dom'
import '../styles/screenStyles.css';
import Header from "../Components/Header";
import { getCharacterAsync, getCharacterComicsAsync } from "../redux/charactersSlice";
import ComicCardSmall from "../Components/ComicCardSmall";

export default function Character() {
    const dispatch = useAppDispatch();
    const statusChar = useAppSelector(state => state.characters.statusCharacter);
    const statusCharCom = useAppSelector(state => state.characters.statusCharacterComics);
    const comicCharacter = useAppSelector(state => state.characters.characterComics);
    const totalComics = useAppSelector(state => state.characters.total);
    const character = useAppSelector(state => state.characters.character);
    const params = useParams();

    useEffect(() => {
        dispatch(getCharacterAsync(Number(params.characterID)));
        dispatch(getCharacterComicsAsync({ id: Number(params.characterID), limit: 5 }))
    }, [params, dispatch]);

    return(
        <div className="main">
            <Header title={"Personaje"} />
            <div className="main flex-center">
                {statusChar === 'loading' &&
                    <LoadingSpinner />
                }
                {statusChar === 'idle' &&
                    <div className="flex-center main">
                        <img 
                            className='comic-image'
                            src={
                                (character?.thumbnail && character?.thumbnail.path && character?.thumbnail.extension) ?
                                character?.thumbnail.path + '.' + character?.thumbnail.extension :
                                ''
                            } 
                            alt='Imagen no disponible'
                        />
                        <h1>{character?.name}</h1>
                        {character?.description &&
                            <p><b>Descripción:</b> {character?.description}</p>
                        }
                        {statusCharCom === 'loading' &&
                            <LoadingSpinner />
                        }
                        {statusCharCom === 'idle' && comicCharacter && comicCharacter.length > 0 &&
                            <div style={{ width: '80%'  }}>
                                <p><b>Comics donde aparece:</b> {comicCharacter?.length} de {totalComics}</p>
                                <div className="display-cards">
                                    {comicCharacter.map(comic => 
                                        <ComicCardSmall
                                            id={comic.id}
                                            title={comic.title}
                                            image={comic.thumbnail}
                                        />
                                    )}
                                </div>
                            </div>
                        }
                        {statusCharCom === 'failed' &&
                        <div className="alert alert-danger" role="alert">
                            Hubo un error al pedir los datos del personaje
                        </div>
                        }
                    </div>
                }
                {statusChar === 'failed' &&
                <div className="alert alert-danger" role="alert">
                    Hubo un error al pedir los datos de los comics
                </div>
                }
            </div>
        </div>
    )
    
}