import Dropdown from 'react-bootstrap/Dropdown';
import { useAppDispatch } from "../app/hooks";
import { setOrder, setPage } from "../redux/comicsSlice";

export default function OrderBy(){
    const dispatch = useAppDispatch();
    return(
        <Dropdown>
            <Dropdown.Toggle 
                id="dropdown-basic"
            >
                Order by
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("title"));
                        dispatch(setPage(0));
                    }}
                >
                    Title (+)
                </Dropdown.Item>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("-title"));
                        dispatch(setPage(0));
                    }}
                >
                    Title (-)
                </Dropdown.Item>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("focDate"));
                        dispatch(setPage(0));
                    }}
                >
                    Final Order Cut-Off Date (+)
                </Dropdown.Item>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("-focDate"));
                        dispatch(setPage(0));
                    }}
                >
                    Final Order Cut-Off Date (-)
                </Dropdown.Item>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("onsaleDate"));
                        dispatch(setPage(0));
                    }}
                >
                    On Sale Date (+)
                </Dropdown.Item>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("-onsaleDate"));
                        dispatch(setPage(0));
                    }}
                >
                    On Sale Date (-)
                </Dropdown.Item>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("modified"));
                        dispatch(setPage(0));
                    }}
                >
                    Modified (+)
                </Dropdown.Item>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("-modified"));
                        dispatch(setPage(0));
                    }}
                >
                    Modified (-)
                </Dropdown.Item>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("issueNumber"));
                        dispatch(setPage(0));
                    }}
                >
                    Issue Number (+)
                </Dropdown.Item>
                <Dropdown.Item 
                    onSelect={() => {
                        dispatch(setOrder("-issueNumber"));
                        dispatch(setPage(0));
                    }}
                >
                    Issue Number (-)
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}