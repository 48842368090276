import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import marvelApi from '../api';
import { Comic } from '../interfaces/ComicInterface';

export interface ComicState {
  comic: Comic | undefined;
  comics: Comic[] | undefined;
  offset: number | undefined;
  total: number | undefined;
  count: number | undefined;
  page: number;
  order: string;
  statusComic: 'idle' | 'loading' | 'failed';
  statusComics: 'idle' | 'loading' | 'failed';
}

const initialState: ComicState = {
  comic: undefined,
  comics: undefined,
  offset: 0,
  total: 0,
  count: 0,
  page: 0,
  order: 'title',
  statusComic: 'idle',
  statusComics: 'idle',
};

interface PageRequest {
    page: number;
    orderBy: string | undefined;
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getComicsAsync = createAsyncThunk(
  'comics/getComics',
  async (request: PageRequest) => {
    const response = await marvelApi.comics.getPage(request.page, request.orderBy);
    return {...response.data, page: request.page};
  }
);

export const getComicAsync = createAsyncThunk(
  'comics/getComic',
  async (id: number) => {
    const response = await marvelApi.comics.getComic(id);
    return response.data;
  }
);

export const ComicsSlice = createSlice({
  name: 'comics',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setOrder: (state, action: PayloadAction<string>) => {
      state.order = action.payload
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getComicsAsync.pending, (state) => {
        state.statusComics = 'loading';
        state.comics = undefined;
      })
      .addCase(getComicsAsync.fulfilled, (state, action) => {
        state.statusComics = 'idle';
        state.comics = action.payload?.results;
        state.offset = action.payload?.offset;
        state.total = action.payload?.total;
        state.count = action.payload?.count;
      })
      .addCase(getComicsAsync.rejected, (state) => {
        state.statusComics = 'failed';
      });
    builder
      .addCase(getComicAsync.pending, (state) => {
        state.statusComic = 'loading';
        state.comic = undefined;
      })
      .addCase(getComicAsync.fulfilled, (state, action) => {
        state.statusComic = 'idle';
        state.comic = action.payload?.results ? action.payload.results[0] : undefined;
      })
      .addCase(getComicAsync.rejected, (state) => {
        state.statusComic = 'failed';
      });
  },
});

export const { setPage, setOrder } = ComicsSlice.actions

export default ComicsSlice.reducer;
